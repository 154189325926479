<template>
  <v-container fluid class="scroll-container">
    <div>
      <!-- <v-row no-gutters v-if="$vuetify.breakpoint.xsOnly">
        <v-col cols="6">
          <company-list @company-changed="onCompanyChanged" />
        </v-col>
      </v-row> -->

      <v-row no-gutters class="mobile-spacing">
        <v-col cols="12">
          <analytics-statistics-card-genel :key="companyKey"></analytics-statistics-card-genel>
        </v-col>
      </v-row>
      <template v-if="$vuetify.breakpoint.xsOnly">
        <v-row no-gutters>
          <v-col cols="12">
            <analytics-statistics-card-doviz-treeview :key="companyKey"></analytics-statistics-card-doviz-treeview>
          </v-col>

          <v-col cols="12">
            <account-transactions-list-son-hareketler :key="companyKey"></account-transactions-list-son-hareketler>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row no-gutters>
          <v-col cols="6">
            <analytics-statistics-card-doviz-treeview :key="companyKey"></analytics-statistics-card-doviz-treeview>
          </v-col>

          <v-col cols="6">
            <account-transactions-list-son-hareketler :key="companyKey"></account-transactions-list-son-hareketler>
          </v-col>
        </v-row>
      </template>
    </div>
  </v-container>
</template>

<script>
import store from '@/store'
import AccountTransactionsListSonHareketler from '../accountTransactions/AccountTransactionsListSonHareketler.vue'
//import AnalyticsStatisticsCardDoviz from './AnalyticsStatisticsCardDoviz.vue'
//import AnalyticsStatisticsCardDovizExpanded from './AnalyticsStatisticsCardDovizExpanded.vue'
//import AnalyticsStatisticsCardDovizExpandedTest from './AnalyticsStatisticsCardDovizExpandedTest.vue'
import AnalyticsStatisticsCardDovizTreeview from './AnalyticsStatisticsCardDovizTreeview.vue'
import AnalyticsStatisticsCardGenel from './AnalyticsStatisticsCardGenel.vue'

export default {
  components: {
    AnalyticsStatisticsCardGenel,
    //AnalyticsStatisticsCardDoviz,
    AccountTransactionsListSonHareketler,
    //AnalyticsStatisticsCardDovizExpandedTest,
    AnalyticsStatisticsCardDovizTreeview,
  },

  data() {
    return {
      companyKey: 0,
    }
  },
  methods: {
    onCompanyChanged(company) {
      debugger
      store.commit('COMPANY_CUSTOMER', company)
      this.companyKey += 1 // Bileşenleri yeniden render etmek için anahtarı değiştirin
    },
  },
  watch: {
    '$store.state.companyCustomer'() {
      debugger
      this.companyKey += 1 // Bileşenleri yeniden render etmek için anahtarı değiştirin
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
.container-text {
  display: flex;
  flex-wrap: wrap; /* Metin boyutları değiştikçe alt satıra geçmesini sağlar */
}

@media (max-width: 600px) {
  .mobile-spacing {
    margin-top: 40px; /* Mobil ekranlarda daha fazla boşluk */
    padding-left: 0; /* Soldan boşlukları kaldırın */
    padding-right: 0; /* Sağdan boşlukları kaldırın */
  }
}

.scroll-container {
  overflow-x: auto; /* Yatay kaydırma sağlar */
  -webkit-overflow-scrolling: touch; /* Daha pürüzsüz kaydırma için */
  padding-left: 0px; /* Sol padding */
  padding-right: 0px; /* Sağ padding */
}

.v-row {
  margin-left: -2px; /* Sol margin */
  margin-right: -2px; /* Sağ margin */
}

.v-col {
  padding-left: 2px; /* Sol padding */
  padding-right: 2px; /* Sağ padding */
}
</style>
