<template>
  <v-card class="d-flex flex-column">
    <v-card-title class="font-weight-semibold">
      <v-avatar class="mx-5" color="secondary">
        <v-icon color="white" size="24">
          {{ icons.mdiChartPie }}
        </v-icon>
      </v-avatar>
      <span class="statistics-title">{{ statistics }}</span>
    </v-card-title>
    <div v-if="chartSeries.length">
      <vue-apex-charts
        class="flex-grow-1"
        :options="chartOptionsComputed"
        :series="chartSeries"
        :height="400"
        type="pie"
      ></vue-apex-charts>
    </div>

    <div v-else>loading</div>

    <v-card-text class="font-weight-semibold text-center text--primary">
      {{ statTitle }}
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiChartPie } from '@mdi/js'
import { computed } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: { VueApexCharts },
  props: {
    statistics: { type: String, default: '' },
    statTitle: { type: String, default: '' },
    chartSeries: {
      type: Array,
      default: () => [],
    },
    chartConfig: {
      type: Object,
      default: null,
    },
    chartLabels: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const chartOptions = {
      chart: {
        id: 'apexchart-example',
        type: 'pie',
        height: '400px',
      },
      title: {
        text: '',
        align: 'center',
        margin: 30,
        style: {
          fontSize: '20px',
          fontWeight: 'bold',
          color: '#263238',
        },
      },

      labels: props.chartLabels, // ["Apple", "Mango", "Orange", "Watermelon"],
      legend: {
        show: true,
        position: 'bottom',
      },
      dataLabels: {
        style: {
          fontSize: '10px',
          fontWeight: 'bold',
        },
        background: {
          enabled: false,
          foreColor: '#483E3E',
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: '#2E93fA',
        },

        enabled: true,
        formatter: function (value) {
          return Math.round(value) + '%'
        },
        font: {
          weight: 'bold',
          size: 14,
        },
      },
      colors: [
        '#2E93fA',
        '#46DA62',
        '#242E8A',
        '#E91E63',
        '#FF9800',
        '#FFBF00',
        '#6495ED',
        '#9FE2BF',
        '#40E0D0',
        '#CCCCFF',
      ],

      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }

    const chartOptionsComputed = computed(() => {
      if (props.chartLabels.length > 0) {
        chartOptions.labels = props.chartLabels
        const options = JSON.parse(JSON.stringify(chartOptions))

        console.log('labels', props.chartLabels)
        console.log('options', options)

        return options
      }

      if (props.chartConfig === null) {
        const options = JSON.parse(JSON.stringify(chartOptions))
        console.log('options', options)
        return options
      }

      return props.chartConfig
    })

    const chartSeriessComputed = computed(() => {
      if (props.chartSeries.length > 0) {
        console.log('series', props.chartSeries)

        return props.chartSeries
      }
    })

    return {
      chartOptionsComputed,
      chartSeriessComputed,
      icons: {
        mdiChartPie,
      },
    }
  },

  watch: {
    chartSeries(val) {
      console.log('chartseris', val)
    },
    chartLabels(val) {
      console.log('chartlabes', val)
    },
  },
}
</script>

<style lang="scss">
#sessions-chart {
  .apexcharts-series {
    &[seriesName='Earning'] {
      transform: scaleY(0.965);
    }
    &[seriesName='Expense'] {
      transform: scaleY(1.035);
    }
  }
  .statistics-title {
    font-size: 1.25rem; /* Varsayılan yazı boyutu */
  }
}

.statistics-title {
  font-size: 18px; /* Varsayılan yazı boyutu */
}

@media (max-width: 599px) {
  .statistics-title {
    font-size: 16px; /* Mobil ekranlarda daha küçük yazı boyutu */
  }
}
</style>
